import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 48 48"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Chat"
      className="cls-1"
      d="M44.517,42.671l2.814,1.876A1.5,1.5,0,0,1,48,45.8v0.7A1.5,1.5,0,0,1,46.5,48H32c-9.719,0-16-6.281-16-16s6.281-16,16-16,16,6.281,16,16A16.453,16.453,0,0,1,44.517,42.671ZM32,22c-6.449,0-10,3.551-10,10s3.551,10,10,10,10-3.551,10-10S38.449,22,32,22ZM16,6C9.551,6,6,9.551,6,16c0,4.565,1.786,7.671,5.114,9.1A1.478,1.478,0,0,1,12,26.461V30.5A1.5,1.5,0,0,1,10.5,32h-9A1.5,1.5,0,0,1,0,30.5V29.8a1.5,1.5,0,0,1,.669-1.25l2.814-1.876A16.454,16.454,0,0,1,0,16C0,6.281,6.281,0,16,0c7.816,0,13.4,4.071,15.284,10.719A1.007,1.007,0,0,1,30.318,12H26.784a2.019,2.019,0,0,1-1.817-1.18C23.484,7.681,20.429,6,16,6Z"
    />{" "}
  </svg>
))
