import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props } from "./types"
import { graphql, useStaticQuery } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import { IconProps } from "src/components/Update/types"
import FeatureDesign from "src/components/Icons/Design"
import FeatureTasks from "src/components/Icons/Task"
import FeatureChat from "src/components/Icons/Chat"
import Power from "src/components/Icons/Powerful"
import Project from "src/components/Icons/Projects"
import Calendar from "src/components/Icons/Calendar"
import Link from "src/utils/Link"
import { usePages } from "src/context/pages"

const Icon: FC<IconProps> = ({ icon }) => {
  // noinspection JSRedundantSwitchStatement
  switch (icon) {
    case "design":
      return <FeatureDesign className={styles.feature} aria-hidden="true" />
    case "task":
      return <FeatureTasks className={styles.feature} aria-hidden="true" />
    case "collaborative":
      return <FeatureChat className={styles.feature} aria-hidden="true" />
    case "power":
      return <Power className={styles.feature} aria-hidden="true" />
    case "project":
      return <Project className={styles.feature} aria-hidden="true" />
    default:
      return <Calendar className={styles.feature} aria-hidden="true" />
  }
}

const BlogSubFooter: FC<Props> = ({}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/BlogSubFooter\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            title
            tryIt
            list {
              name
              description
              icon
              link
            }
          }
          relativePath
        }
      }
    }
  `)
  const texts = useComponentTexts(data)
  const { getPage } = usePages()
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>{texts.title}</h2>
        <Link to={getPage("signup").url} className={styles.link}>
          {texts.tryIt}
        </Link>
      </div>
      <div className={styles.content}>
        {texts.list.map((item: any) => (
          <Link
            to={getPage(item.link).url}
            className={styles.featureContent}
            key={item.icon}
          >
            <Icon icon={item.icon} />
            <h3 className={styles.featureName}>{item.name}</h3>
            <p className={styles.featureDescription}>{item.description}</p>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default BlogSubFooter
