import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 92 104"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      className="cls-1"
      fillRule="evenodd"
      d="M12,8V3A3,3,0,0,0,9,0H3A3,3,0,0,0,0,3v98a3,3,0,0,0,3,3H9a3,3,0,0,0,3-3V76H40v9a3,3,0,0,0,3,3H89a3,3,0,0,0,3-3V23a3,3,0,0,0-3-3H52V11a3,3,0,0,0-3-3H12Zm0,12V64H40V20H12ZM80,32H52V76H80V32Z"
    />
  </svg>
))
