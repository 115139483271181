import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 106 100"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      className="cls-1"
      fillRule="evenodd"
      d="M104.824,29.308L54.772,79.356a6.017,6.017,0,0,0-1.58,3.005C50.823,93.218,42.224,99.94,29.934,99.94H2.984a3,3,0,0,1-3-2.994v-1.4a3,3,0,0,1,1.334-2.493l3.318-2.213a3,3,0,0,0,1.334-2.486L6,76.015c0-12.258,6.7-20.844,17.514-23.235A6.029,6.029,0,0,0,26.507,51.2l50.1-50.1a2.993,2.993,0,0,1,4.231,0l23.981,23.975A2.99,2.99,0,0,1,104.824,29.308ZM29.934,64.051c-7.942,0-11.968,4.025-11.968,11.964L17.947,87.978H29.934c7.942,0,11.968-4.025,11.968-11.963S37.875,64.051,29.934,64.051ZM78.728,15.91L40.533,54.1a20.685,20.685,0,0,1,11.3,11.267L90.014,27.192Z"
    />
  </svg>
))
