import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 93 90"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      className="cls-1"
      fillRule="evenodd"
      d="M89.933,90H7.509a3.007,3.007,0,0,1-3.015-3V81a3.008,3.008,0,0,1,3.015-3H89.933a3.008,3.008,0,0,1,3.016,3v6A3.008,3.008,0,0,1,89.933,90Zm0-28H59.778a3.008,3.008,0,0,1-3.016-3V53a3.008,3.008,0,0,1,3.016-3H89.933a3.008,3.008,0,0,1,3.016,3v6A3.008,3.008,0,0,1,89.933,62Zm0-28H75.861a3.008,3.008,0,0,1-3.016-3V25a3.008,3.008,0,0,1,3.016-3H89.933a3.008,3.008,0,0,1,3.016,3v6A3.008,3.008,0,0,1,89.933,34ZM34.81,65.329A6.03,6.03,0,0,1,29.8,68H27.435a6.031,6.031,0,0,1-5.012-2.671L0.849,33.112A2,2,0,0,1,2.522,30h7.515a6.049,6.049,0,0,1,5.025,2.672L28.617,52.915,62.262,2.672A6.049,6.049,0,0,1,67.287,0H74.8a2,2,0,0,1,1.675,3.108Z"
    />
  </svg>
))
