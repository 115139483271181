import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 112 108"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      className="cls-1"
      fillRule="evenodd"
      d="M81.545,32L99.376,5.253A3.379,3.379,0,0,0,96.564,0h-44A16,16,0,0,0,39.25,7.125L0.516,65.226A3.071,3.071,0,0,0,3.07,70H38v35a3,3,0,0,0,3,3H55.437a16,16,0,0,0,13.313-7.125l42.734-64.1A3.07,3.07,0,0,0,108.93,32H81.545ZM80.462,11.99L62.624,38.747A3.379,3.379,0,0,0,65.436,44H92.244L58.765,94.219A4,4,0,0,1,55.437,96H50V61a3,3,0,0,0-3-3H19.756L49.235,13.781A4,4,0,0,1,52.563,12Z"
    />
  </svg>
))
