import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 96 100"
    width="48"
    height="48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      className="cls-1"
      fillRule="evenodd"
      d="M80,100H16A16,16,0,0,1,0,84V28A16,16,0,0,1,16,12h8V3a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v9H60V3a3,3,0,0,1,3-3h6a3,3,0,0,1,3,3v9h8A16,16,0,0,1,96,28V84A16,16,0,0,1,80,100Zm0-76H16a4,4,0,0,0-4,4v8H84V28A4,4,0,0,0,80,24Zm4,24H12V84a4,4,0,0,0,4,4H80a4,4,0,0,0,4-4V48Z"
    />
  </svg>
))
