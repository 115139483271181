import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 56 56"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Cloud_Download"
      data-name="Cloud Download"
      className="cls-1"
      fillRule="evenodd"
      d="M42,40H41.5A1.5,1.5,0,0,1,40,38.5v-3A1.5,1.5,0,0,1,41.5,34H42c5.159,0,8-2.841,8-8,0-3.424-1.246-7.585-7.181-7.964l-3.171-.2A1.5,1.5,0,0,1,38.3,16.748l-0.87-3.056C36.019,8.732,31.959,6,26,6,18.827,6,14.471,9.968,14.049,16.888l-0.194,3.189a1.5,1.5,0,0,1-1.09,1.352L9.69,22.3C7.241,22.988,6,24.907,6,28c0,3.982,2.019,6,6,6h2.5A1.5,1.5,0,0,1,16,35.5v3A1.5,1.5,0,0,1,14.5,40H12C4.711,40,0,35.289,0,28c0-5.872,3.061-10.066,8.06-11.477C8.675,6.439,15.57,0,26,0c8.792,0,15.074,4.575,17.2,12.049C51.015,12.548,56,17.906,56,26,56,34.5,50.5,40,42,40ZM19.557,42.445a1.5,1.5,0,0,1,2.121,0L25,45.779V25.5A1.5,1.5,0,0,1,26.5,24h3A1.5,1.5,0,0,1,31,25.5V45.749l3.293-3.3a1.5,1.5,0,0,1,2.121,0l2.121,2.121a1.5,1.5,0,0,1,0,2.121l-8.407,8.419a3.028,3.028,0,0,1-4.283,0l-8.407-8.419a1.5,1.5,0,0,1,0-2.121Z"
    />
  </svg>
))
