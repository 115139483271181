import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import * as styles from "./styles.module.scss"
import BlogSubFooter from "src/components/BlogSubFooter"
import GetStarted from "src/components/Banners/GetStarted"
import Download from "src/components/Icons/Download"
import Flow from "src/components/Icons/Logo"
import ArrowLink from "src/components/ArrowLink"
import Link from "src/utils/Link"
import Img from "gatsby-image"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <div className={`content-wrapper ${styles.header}`}>
        <h1 className={styles.title}>{texts.header}</h1>
        <div
          className={styles.richText}
          dangerouslySetInnerHTML={{ __html: texts.subheader }}
        />
        <Link
          to="https://www.dropbox.com/sh/36yh38eksn9b4sn/AACcG7oyqkkyGCYtrhWxswela?dl=0."
          target="_blank"
          className={`button-new button-new--primary`}
          rel="noopener noreferrer"
        >
          {texts.headerButtonText}
        </Link>
      </div>

      <div className={styles.main}>
        <div className={`content-wrapper ${styles.content}`}>
          <div className={styles.interviews}>
            {texts.interviews.map(item => (
              <Link
                to={item.link}
                key={item.name}
                className={styles.interview}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Img
                  fluid={item.logo.childImageSharp.fluid}
                  style={{ height: item.logoH, width: item.logoW }}
                />
                <h3 className={styles.interviewName}>{item.name}</h3>
                <ArrowLink
                  text={item.linkText}
                  to={item.link}
                  as="span"
                  className={styles.arrowLink}
                />
              </Link>
            ))}
          </div>
          <div className={styles.assets}>
            {texts.assets.map(asset => (
              <Link
                to={asset.link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.asset}
              >
                <div className={styles.imageContainer}>
                  {asset.img ? (
                    <Img
                      fluid={asset.img.childImageSharp.fluid}
                      style={{ height: asset.imgH, width: asset.imgW }}
                    />
                  ) : (
                    <Flow className={styles.flowLogo} />
                  )}
                </div>
                <div className={styles.textContainer}>
                  <h3 className={styles.assetName}>{asset.title}</h3>
                  <Download />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className={`content-wrapper ${styles.whatflowContainer}`}>
        <BlogSubFooter />
        <GetStarted className={styles.banner} logosTitle={texts.logosTitle} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subheader
        headerButtonText
        logosTitle
        interviews {
          name
          linkText
          link
          logo {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          logoH
          logoW
        }
        assets {
          title
          link
          img {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgH
          imgW
        }
      }
    }
  }
`

export default Template
